<template>
  <v-dialog
    v-model="toogleEditAccountsModal"
    max-width="500"
    persistent
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{
          $vuetify.lang.t("$vuetify.titles.edit", [
            $vuetify.lang.t("$vuetify.menu.accounting")
          ])
        }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="formValid"
          class="my-10"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                :label="$vuetify.lang.t('$vuetify.menu.bank')"
                v-model="editAccount.bank"
                :items="banks"
                item-text="name"
                return-object
                auto-select-first
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-text-field
                  v-if="!editAccount.default"
                v-model="editAccount.name"
                :label="$vuetify.lang.t('$vuetify.firstName')"
                :rules="formRule.required"
                :value="editAccount.name"
                required
              />
              <v-text-field
                  v-else
                  :label="$vuetify.lang.t('$vuetify.firstName')"
                  :value="getNameCategory(editAccount)"
                  required
                  :readonly="editAccount.default===1"
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="editAccount.code"
                :label="$vuetify.lang.t('$vuetify.accounting_category.code')"
                :rules="formRule.required"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-textarea
                height="100px"
                v-model="editAccount.description"
                :label="$vuetify.lang.t('$vuetify.description')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="mb-2"
          :disabled="isActionInProgress"
          @click="toogleEditAccountsModal(false)"
        >
          <v-icon>mdi-close</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.cancel") }}
        </v-btn>
        <v-btn
          :disabled="!formValid || isActionInProgress"
          :loading="isActionInProgress"
          class="mb-2"
          color="primary"
          @click="createNewAccount"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $vuetify.lang.t("$vuetify.actions.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'EditAccountingAccount',
  data () {
    return {
      formValid: false,
      formRule: this.$rules
    }
  },
  computed: {
    ...mapState('account', ['saved', 'editAccount', 'isActionInProgress']),
    ...mapState('bank', ['banks'])
  },
  created () {
    this.formValid = false
  },
  methods: {
    ...mapActions('account', ['updateAccount', 'toogleEditAccountsModal', 'getAccounts']),
    getNameCategory (item) {
      const splitName = item.name.split('-')
      return splitName[0] + '-' + this.$vuetify.lang.t('$vuetify.accounting_category.' + splitName[1])
      // return item
    },
    lettersNumbers (event) {
      const regex = new RegExp('^[a-zA-Z0-9 ]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
    async createNewAccount () {
      if (this.$refs.form.validate()) {
        this.loading = true
        await this.updateAccount(this.editAccount)
      }
    }
  }
}
</script>

<style scoped></style>
